import { render, staticRenderFns } from "./field-column.vue?vue&type=template&id=70fb12ba&scoped=true&"
import script from "./field-column.vue?vue&type=script&lang=js&"
export * from "./field-column.vue?vue&type=script&lang=js&"
import style0 from "./field-column.vue?vue&type=style&index=0&id=70fb12ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fb12ba",
  null
  
)

export default component.exports